import React from "react"
import { Box, Button, Container, Flex, Heading, Image, Text } from "@chakra-ui/react"

import Link from "@app/components/Link"

type Props = {
  item: any
  handleTrackingClick: () => void
}

const HeroItem: React.FC<Props> = ({ item, handleTrackingClick }) => {
  const isImage = item?.image
  const alignment = item.alignment?.toString().toLowerCase() || "center"

  return (
    <Container as="section" maxW="11xl" px={[4, 8]} bgColor={item.bgColor?.hex} color={item.theme === "Dark" ? "black" : "white"}>
      <Box position="relative" w={isImage ? "fit-content" : "100%"} h="auto" minH={isImage ? "unset" : "60vh"} mx="auto">
        {item?.image && (
          <Box bgColor={item.image.backgroundColor} position="relative" textAlign="center">
            <Image {...item.image} />
          </Box>
        )}
        <Flex
          position={isImage ? "absolute" : "relative"}
          w="100%"
          h="100%"
          minH={isImage ? "unset" : "60vh"}
          top="0"
          left="0"
          alignItems={alignment === "center" ? "center" : "flex-end"}
          justifyContent={alignment === "center" ? "center" : alignment === "right" ? "flex-end" : "end"}
        >
          <Box p={[4, 8]} textAlign={alignment}>
            {item?.title && (
              <Heading as="p" size="xl">
                {item.title}
              </Heading>
            )}
            {item?.content && <Text>{item.content}</Text>}
            {item?.link?.title && item?.link?.url && (
              <Box mt={4}>
                <Link title={item.link.title} to={item.link.url} onClick={handleTrackingClick}>
                  <Button>
                    <Text color="white">{item.link.title}</Text>
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

export default React.memo(HeroItem)
